<template>
  <div class="repaymentCode">
    <mt-header :title="$t('repayment')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">
        {{ $t('repaymentLoanMsg1') }}
      </div>
      <mt-cell :title="$t('tasaTramitacion')">
        <span style="color: #F15234; font-weight: bold;">{{ amount | formatMoney }}</span>
        <span style="margin-left: 5px;">MXN</span>
      </mt-cell>
      <mt-cell :title="code">
        <mt-button @click="onCopy" class="copy" size="small" plain>{{ $t('copy') }}</mt-button>
      </mt-cell>
      <img id="barcode" :src="url" />
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { payBarCode, payClabe } from '../../utils/api'
import JsBarcode from 'jsbarcode'
export default {
  name: 'RepaymentCode',
  components: {
    callPhone
  },
  data() {
    return {
      code: '',
      url: ''
    }
  },
  computed: {
    applyId() {
      return this.$route.query.applyId
    },
    type() {
      return this.$route.query.type
    },
    amount() {
      return this.$route.query.amount
    },
  },
  mounted() {
    if (this.type == 'OXXO') {
      this.getBarCode(); // 获取条形码
    } else {
      this.getClabe(); // 获取还款码
    }
  },
  methods: {
    async getBarCode() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.$axios({
        method: "POST",
        url: payBarCode,
        params: {
          applyId: this.applyId,
          amount: this.amount
        }
      }).then((e) => {
        if (e.status.code == "000") {
          this.code = e.body.barcode;
          this.url = e.body.barcodeUrl;
          JsBarcode('#barcode', e.body.barcodeUrl, {
            format: 'CODE39',
            lineColor: '#000',
            background: '#EBEEF5',
            width: 2,
            height: 40,
            displayValue: false
          })
        }
      }).catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getClabe() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.$axios({
        method: "POST",
        url: payClabe,
        params: {
          applyId: this.applyId,
          amount: this.amount
        }
      }).then((e) => {
        if (e.status.code == "000") {
          this.code = e.body.clabe;
        }
      }).catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
    },
    onCopy() {
      let transfer = document.createElement('input');
      document.body.appendChild(transfer);
      transfer.readonly = 'readonly';
      transfer.value = this.code;
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy');
      }
      transfer.blur();
      this.$toast(this.$t('copySuccess'));
      document.body.removeChild(transfer);
    }
  }
}
</script>

<style lang="scss" scoped>
.repaymentCode {
  .content {
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
    .tips {
      padding: 10px;
      font-size: 12px;
      color: #F15234;
    }
    #barcode {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
